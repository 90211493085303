<template>
  <div class="pt-16">
    <div
      class="w-full h-full bg-fixed bg-contain md:bg-center bg-no-repeat"
      :style="`background-image: url(${bgImage})`"
    >
      <div
        class="w-full h-75vh md:h-50vh lg:h-75vh py-12 px-4 md:px-0 flex items-center"
      >
        <div class="w-full bg-softGrey80">
          <p
            class="md:w-768 lg:w-1024 m-auto p-4 text-xl text-pulpBlue text-center font-semibold"
          >
            nexgen is a Silicon Valley company based in San Jose, California
            with offices and channel partners around the world. nexgen is
            dedicated to providing English language learners with effective
            learning content, which when combined with real-time advanced Al,
            allows learners to achieve their English goals—guaranteed.
          </p>
        </div>
      </div>
      <div class="bg-white mb-48 py-16">
        <div
          class="md:w-768 lg:w-1024 mx-auto pb-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div class="w-full mt-10 text-left">
            <div class="flex flex-col md:flex-row items-start justify-between">
              <img
                rel="prefetch"
                class="md:h-40 lg:h-52"
                src="@/assets/images/icons/big-data2.png"
                alt="2.CERT-YOUNG-FEMALE"
              />
              <div class="pt-4 md:pt-8 md:pl-10">
                <!-- <h3 class="text-2xl text-primary font-semibold">
                  Fully Accessible Courses with neoStudy Online and/or neoStudy
                  App​
                </h3> -->
                <p
                  class="w-full md:w-10/12 lg:w-8/12 mt-0 md:mt-2 lg:mt-4 mx-auto md:mx-0 text-lg font-light text-textBlack text-justify"
                >
                  Backed by over 30 years of big data, neo will automatically
                  individualize the content for each learner to ensure mastery
                  of key concepts.​
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white py-16">
        <div
          class="md:w-768 lg:w-1024 mx-auto px-4 md:px-0 flex flex-col-reverse md:flex-row justify-between"
        >
          <div class="mx-auto text-textBlack text-left">
            <h3 class="mb-6 text-2xl text-center text-primary font-semibold">
              More about Nexgen English Online Co​
            </h3>
            <ul
              class="flex flex-wrap md:flex-nowrap justify-center text-textBlack"
            >
              <li class="w-full md:w-4/12 text-left bg-softGrey py-4 px-6">
                <h5>End User License Agreement (EULA) ​</h5>
                <div class="mt-2">
                  <router-link class="text-pulpBlue underline" to="/nexgeneula"
                    >English</router-link
                  >,
                  <router-link
                    class="text-pulpBlue underline"
                    to="/nexgeneulacn"
                    >Chinese</router-link
                  >,
                  <router-link
                    class="text-pulpBlue underline"
                    to="/nexgeneulath"
                    >Thai</router-link
                  >,
                  <router-link
                    class="text-pulpBlue underline"
                    to="/nexgeneulasp"
                    >Spanish</router-link
                  >,
                  <router-link
                    class="text-pulpBlue underline"
                    to="/nexgeneulaid"
                    >Bahasa Indonesia</router-link
                  >
                </div>
              </li>
              <li
                class="w-full md:w-4/12 bg-softGrey py-4 px-6 md:mx-2 my-1 md:my-0"
              >
                <h5>Our Privacy Policy​​</h5>
                <div class="mt-2">
                  <router-link
                    class="text-pulpBlue underline"
                    to="/privacypolicy"
                    >English</router-link
                  >,
                  <router-link
                    class="text-pulpBlue underline"
                    to="/privacypolicycn"
                    >Chinese</router-link
                  >
                </div>
              </li>
              <li class="w-full md:w-4/12 bg-softGrey py-4 px-6">
                <h5>Myneo App Privacy Policy​​​</h5>
                <div class="mt-2">
                  <a
                    class="text-pulpBlue underline"
                    href="/myneoprivacypolicy/webview"
                    target="_blank"
                    >English</a
                  >,
                  <a
                    class="text-pulpBlue underline"
                    href="/myneoprivacypolicycn/webview"
                    target="_blank"
                    >Chinese</a
                  >
                </div>
              </li>
            </ul>
            <div class="mt-10">
              <h5>Follow us or email us at</h5>
              <a
                class="w-max flex items-center"
                href="https://www.facebook.com/Nexgen-neo-1773897936237827/"
                target="_blank"
              >
                <fb-icon-vue width="16" height="16" iconColor="#49c5fe" /><span
                  class="pl-1 underline text-secondary"
                  >Facebook</span
                >
              </a>
              <a
                class="w-max flex items-center"
                href="https://www.instagram.com/nexgen.neo/"
                target="_blank"
              >
                <instagram-icon-vue
                  width="16"
                  height="16"
                  iconColor="#49c5fe"
                /><span class="pl-1 underline text-secondary">Instagram</span>
              </a>
              <a
                class="w-max flex items-center"
                href="https://www.linkedin.com/company/18831449/admin/"
                target="_blank"
              >
                <LinkedinIconVue
                  width="16"
                  height="16"
                  iconColor="#49c5fe"
                /><span class="pl-1 underline text-secondary">Linkedin</span>
              </a>
              <a
                class="w-max flex items-center"
                href="mailto:support@nexgenenglishonline.co"
              >
                <mail-icon-vue
                  :width="16"
                  :height="16"
                  iconColor="#49c5fe"
                /><span class="pl-1 underline text-secondary"
                  >support@nexgenenglishonline.co</span
                >
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouterLink from "../components/RouterLink.vue";
import FbIconVue from "../components/svgicons/FbIcon.vue";
import InstagramIconVue from "../components/svgicons/InstagramIcon.vue";
import LinkedinIconVue from "../components/svgicons/LinkedinIcon.vue";
import MailIconVue from "../components/svgicons/MailIcon.vue";

export default {
  components: {
    FbIconVue,
    InstagramIconVue,
    MailIconVue,
    RouterLink,
    LinkedinIconVue,
  },
  data() {
    return {
      isMobile: window.innerWidth <= 768,
      mobileImageUrl: require("@/assets/images/mobile/about-us720x1280.jpg"),
      desktopImageUrl: require("@/assets/images/icons/15.-AboutUs-Final.jpg"),
    };
  },
  computed: {
    bgImage() {
      return this.isMobile ? this.mobileImageUrl : this.desktopImageUrl;
    },
  },
};
</script>

<style>
</style>